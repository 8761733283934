<template>
       <b-overlay :show="processing" class="container">
        <div class="mb-4">
           <div class="card mb-3">
               <div class="card-header">
             <h3>Manual payment page</h3>
           </div>
           <div class="card-body">
            <b-alert
                :show="!!error"
                fade
                variant="warning"
                dismissible
                @dismissed="error = null"
                >{{ error }}</b-alert
            >
            <!-- <b-form-row>
            </b-form-row> -->
            <reg-form-input v-model.trim.lazy="subscriberId" :disabled="fetchedData" label="Subscriber ID" field-name="Subscriber ID" type="number" step="1"/>
            <reg-form-input v-model.trim.lazy="paymentAmount" :disabled="fetchedData" prepend="£" min="0" max="1000" label="Amount" field-name="Amount" type="number" step="0.01" />
            <b-button v-if="!fetchedData" variant="primary" :disabled="processing || !subscriberId || !paymentAmount" @click="fetchSubscriberInfoAndSetupPayment">Process Payment</b-button>
           <template v-if="fetchedData">
            <hr />
            <p><b>Name:</b> {{  fetchedData.firstName }} {{ fetchedData.lastName }}</p>
            <p><b>Email:</b> {{ fetchedData.email }}</p>
            <p><b>Phone:</b> {{  fetchedData.mobilePhone }}</p>
            <hr />
             <h4 class="mb-2">Payment:</h4>
             <div ref="linkAuthenticationElement" />
             <div ref="paymentElement" />
              <div class="mt-4">
                <b-button variant="primary" :disabled="processing" @click="handleSubmit">Process Payment of {{ currenyformat(paymentAmount) }}</b-button>
              </div>
            </template>
          </div>
          </div>
           </div>
       </b-overlay>

    <!-- <div class="container">
        <h1></h1>
        <p>Sub id</p>
        <p>Amount</p>
        <p>submit button</p>
        <p> after getting subid get some info about then from recurly </p>
    </div> -->
</template>

<script setup lang="ts">

const router = useRouter()

const processing = ref(false)

const error = ref<null | Error | string>(null)

const subscriberId = ref<null | number>(null)

const paymentAmount = ref<null | number>(null)

const currenyformat = (v: number) =>
  new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
    v
  );


import { ref, onMounted } from "vue";
import { type StripeElements, type Stripe } from "@stripe/stripe-js";
import { StripePaymentIntent, useStripePaymentIntentMutation } from '../../iris/src/queries'

// import SrMessages from "./SrMessages.vue";

// const messages = ref([] as string[]);

const linkAuthenticationElement = ref<HTMLElement | null>(null)
const paymentElement = ref<HTMLElement | null>(null)


let stripe: Promise<Stripe>;
let elements: StripeElements;

const fetchedData = ref<StripePaymentIntent | null>(null)

// const config = useRuntimeConfig()

const { mutate } = useStripePaymentIntentMutation({
})

const { public: { stripePublishableKey } } = useRuntimeConfig()

/**
 * get info for stipe payment
 */
const fetchSubscriberInfoAndSetupPayment = async () => {
  try {
    error.value = null
    const result = await mutate({amountInCents: Number(paymentAmount.value) * 100, subscriberId: Number(subscriberId.value)})
    fetchedData.value = result.data.stripeSetupPaymentIntent
    elements = (await stripe).elements({clientSecret: result?.data?.stripeSetupPaymentIntent.clientSecret});
    const stripePaymentElement = elements.create('payment', {
      layout: 'tabs',
      defaultValues: {
        billingDetails: {
          email: fetchedData.value.email,
          phone: fetchedData.value.mobilePhone,
          address: {
            country: 'GB',
            postal_code: fetchedData.value.postCode
          },
          name: `${fetchedData.value.firstName} ${fetchedData.value.lastName}`
        }
      }
    });
    const stripeLinkAuthenticationElement = elements.create("linkAuthentication", {
      defaultValues: {
        email: fetchedData.value.email
      }
    });

    await nextTick()

    stripePaymentElement.mount(paymentElement.value!);
    stripeLinkAuthenticationElement.mount(linkAuthenticationElement.value!);

  } catch (e) {
    error.value = e.message
    return
  }
  processing.value = false;
//   const { clientSecret, error: backendError } = await fetch("/api/create-payment-intent").then((res) => res.json());
//   if (backendError) {
//     messages.value.push(backendError.message);
//   }
// messages.value.push(`Client secret returned.`);


};

const handleSubmit = async () => {
if (processing.value) {
  return;
}
error.value = null;
processing.value = true;

const { error: stripeError } = await (await stripe).confirmPayment({
elements,
confirmParams: {
  return_url: `${window.location.origin}${router.match({
      name: 'manual-payment-return',
      query: {
        subscriberId: `${subscriberId.value}`,
        amount: `${paymentAmount.value}`,
        email: fetchedData.value.email,
        firstName: fetchedData.value.firstName,
        lastName: fetchedData.value.lastName,
        postCode: fetchedData.value.postCode
      }}).fullPath}`
  }
});

if (stripeError.type === "card_error" || stripeError.type === "validation_error") {
  error.value = stripeError.message;
} else {
  error.value = "An unexpected error occured.";
}

processing.value = false;

}

onMounted(async () => {
  stripe = import('@stripe/stripe-js/pure').then(({ loadStripe }) => loadStripe(stripePublishableKey))
})
</script>